<template>
  <v-alert prominent type="info" v-if="showSurvey()">
    <v-row align="center">
      <v-col class="grow">
        {{ $t("alerts.fill-survey") }}
      </v-col>
      <v-col class="shrink">
        <v-btn @click="goToSurvey()">
          <v-icon left>touch_app</v-icon>
          {{ $t("alerts.to-survey") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "AlertForSurvey",
  data: () => ({
    surveyUrl: "https://survey.zohopublic.com/zs/oLCzCz",
    surveyKey: "satisfaction202208",
    surveyDeadline: "2022-08-24",
  }),
  props: ["userData"],
  methods: {
    async goToSurvey() {
      window.open(this.surveyUrl, "_blank");
      this.$emit("updateSurveyData", this.surveyKey);
    },
    showSurvey() {
      //show surver only for bfh, mok lat users untill 23.08.
      let show = false;
      const deadline = new Date(this.surveyDeadline);
      const today = new Date();

      //check deadline
      if (today < deadline) {
        //check service
        if (
          this.userData.aiderServices &&
          (this.userData.aiderServices.indexOf("bfh") > -1 ||
            this.userData.aiderServices.indexOf("neighborhood") > -1)
        ) {
          show = true;
          //show only once
          if (
            this.userData.surveys &&
            this.userData.surveys.satisfaction202208
          ) {
            show = false;
          }
        }
      }

      return show;
    },
  },
};
</script>

<style></style>
