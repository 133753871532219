<template>
  <v-row>
    <v-col cols="12" class="text-center">
      <custom-button
        text="Darba grafiks"
        @click="dialogOpen = true"
        icon="edit_calendar"
      />
      <v-dialog v-model="dialogOpen" persistent max-width="800">
        <loading-data
          v-if="progressBar"
          feedbackText="Atjauno grafiku"
        ></loading-data>
        <v-card class="px-2 center">
          <v-row align="center" justify="center">
            <h1 class="py-5">Darba grafiks</h1>
          </v-row>
          <v-col
            v-for="(item, index) in daysList"
            :key="index"
            :class="['addTimeItem mb-3', { errorTimeBox: item.haveError }]"
          >
            <v-col cols="12" justify="flex-start">
              <h3>
                {{ $t(`general.weekdays.${item.day}`) }} ({{ item.dateKey }})
              </h3>
            </v-col>
            <v-row align="center" class="px-5">
              <!-- errorTimeBox -->
              <v-col cols="12" sm="9" class="timeBox">
                <v-row justify="center" align="center" class="startTime">
                  <v-col cols="12" sm="4">
                    <h4>Sākuma laiks:</h4>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      :label="$t('general.hours')"
                      :rules="hourRules"
                      v-model="item.startHours"
                      type="number"
                      :disabled="item.isOffDay"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      :label="$t('general.minutes')"
                      :rules="minutesRules"
                      v-model="item.startMinutes"
                      type="number"
                      :disabled="item.isOffDay"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="12" sm="4">
                    <h4>Beigu laiks:</h4>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      :label="$t('general.hours')"
                      :rules="hourRules"
                      v-model="item.endHours"
                      type="number"
                      :disabled="item.isOffDay"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      :label="$t('general.minutes')"
                      :rules="minutesRules"
                      v-model="item.endMinutes"
                      type="number"
                      :disabled="item.isOffDay"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3">
                <v-checkbox
                  v-model="item.isOffDay"
                  label="Brīvdiena"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <custom-button
              type="textBtn"
              icon="save"
              :text="$t('btn.save')"
              @click="saveSchedule()"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import CustomButton from "./CustomButton.vue";
import { db } from "@/main";
import {
  hourRules as hourRulesImported,
  minutesRules as minuteRulesImported,
} from "@/helpers/validations.js";
import { find, forEach, includes, isEmpty, isEqual } from "lodash";
import LoadingData from "./utilities/LoadingData.vue";
import { dayInRiga, formatDateInRiga } from "@/helpers/dayjsDates";
export default {
  components: { CustomButton, LoadingData },
  props: ["selectedUser"],
  data() {
    return {
      dialogOpen: false,
      hourRules: hourRulesImported,
      minutesRules: minuteRulesImported,
      progressBar: false,
      daysList: [],
      user: null,
    };
  },
  watch: {
    dialogOpen(val) {
      if (val) {
        this.loadSchedule();
      }
    },
    selectedUser(val) {
      this.user = val;
    },
  },
  created() {
    this.user = this.selectedUser;
  },
  methods: {
    loadSchedule() {
      this.daysList = [];
      let allDays = [];
      if (this.selectedUser && this.selectedUser.workDays) {
        allDays = this.selectedUser.workDays;
      } else {
        allDays = [
          {
            day: 1,
            startHours: 0,
            startMinutes: 0,
            endHours: 23,
            endMinutes: 59,
            isOffDay: false,
            haveError: false,
          },
          {
            day: 2,
            startHours: 0,
            startMinutes: 0,
            endHours: 23,
            endMinutes: 59,
            isOffDay: false,
            haveError: false,
          },
          {
            day: 3,
            startHours: 0,
            startMinutes: 0,
            endHours: 23,
            endMinutes: 59,
            isOffDay: false,
            haveError: false,
          },
          {
            day: 4,
            startHours: 0,
            startMinutes: 0,
            endHours: 23,
            endMinutes: 59,
            isOffDay: false,
            haveError: false,
          },
          {
            day: 5,
            startHours: 0,
            startMinutes: 0,
            endHours: 23,
            endMinutes: 59,
            isOffDay: false,
            haveError: false,
          },
          {
            day: 6,
            startHours: 0,
            startMinutes: 0,
            endHours: 23,
            endMinutes: 59,
            isOffDay: false,
            haveError: false,
          },
          {
            day: 0,
            startHours: 0,
            startMinutes: 0,
            endHours: 23,
            endMinutes: 59,
            isOffDay: false,
            haveError: false,
          },
        ];
      }
      //sort days
      const dayinRiga = dayInRiga();
      let daysNumbers = [];
      const weekDays = [1, 2, 3, 4, 5, 6, 0];
      if (dayinRiga > 0) {
        daysNumbers = Array.from(
          { length: 6 - dayinRiga },
          (v, k) => k + dayinRiga + 1
        );
        daysNumbers.push(0);
        forEach(weekDays, (day) => {
          if (!includes(daysNumbers, day) && day <= dayinRiga) {
            daysNumbers.push(day);
          }
        });
      } else {
        daysNumbers = weekDays;
      }
      let allDaysSorted = [];

      forEach(daysNumbers, (dayNumber) => {
        const foundDay = find(allDays, (day) => isEqual(day.day, dayNumber));
        let today = new Date();
        const daysToAdd =
          dayNumber == 0
            ? 7 - dayinRiga
            : dayNumber > dayinRiga
            ? dayNumber - dayinRiga
            : 7 - dayinRiga + dayNumber;
        today.setDate(today.getDate() + daysToAdd);
        foundDay.dateKey = formatDateInRiga(today);
        allDaysSorted.push(foundDay);
      });
      console.log(allDaysSorted);
      this.daysList = allDaysSorted;
    },
    async saveSchedule() {
      let haveError = false;
      this.progressBar = true;
      forEach(this.daysList, (day) => {
        const wrongStartHours =
          day.startHours < 0 ||
          day.startHours > 23 ||
          isEqual(day.startHours, "");
        const wrongEndHours =
          day.endHours < 0 || day.endHours > 23 || isEqual(day.endHours, "");
        const wrongStartMinutes =
          day.startMinutes < 0 ||
          day.startMinutes > 59 ||
          isEqual(day.startMinutes, "");
        const wrongEndMinutes =
          day.endMinutes < 0 ||
          day.endMinutes > 59 ||
          isEqual(day.endMinutes, "");
        if (
          !day.isOffDay &&
          (wrongStartHours ||
            wrongEndHours ||
            wrongStartMinutes ||
            wrongEndMinutes)
        ) {
          day.haveError = true;
          haveError = true;
        } else {
          day.haveError = false;
        }
      });
      if (!haveError) {
        var conf = confirm(
          "Jaunais darba grafiks stāsies spēkā no rītdienas! Lūdzu pārbaudiet vai rītdienas darba grafiks ir tāds pats kā iecerēts!"
        );
        if (conf) {
          forEach(this.daysList, (day) => {
            delete day.haveError;
          });
          const daysListFormatted = this.daysList.map((day) => {
            return {
              day: parseInt(day.day),
              startHours: parseInt(day.startHours),
              startMinutes: parseInt(day.startMinutes),
              endHours: parseInt(day.endHours),
              endMinutes: parseInt(day.endMinutes),
              isOffDay: day.isOffDay,
            };
          });
          await db
            .collection("users")
            .doc(this.user.userId)
            .update({ workDays: daysListFormatted });
          this.selectedUser.workDays = daysListFormatted;
          this.dialogOpen = false;
        }
      }
      this.progressBar = false;
    },
  },
};
</script>

<style scoped>
.addTimeItem {
  border: 2px solid black;
  padding-bottom: 20px;
}
.timeBox {
  border: 2px solid black;
}
.errorTimeBox {
  border: 2px solid red;
}
.startTime {
  border-bottom: 2px solid black;
}
</style>