<template>
  <v-row v-if="plannedShifts.length > 0">
    <v-col cols="12" class="text-center">
      <v-btn
        :color="
          Object.keys(myVisits).length < plannedShifts.length
            ? 'error'
            : '#9CCC65'
        "
        small
        @click.stop="openMyVisits()"
        :disabled="addingNewVisit"
      >
        <span v-if="!addingNewVisit">
          <v-icon left>timer</v-icon>
          {{ $t("visits.my-visits") }} - {{ Object.keys(myVisits).length }}
          {{ $t("general.from") }}
          {{ plannedShifts.length }}
        </span>
        <span v-else>
          <v-progress-circular
            indeterminate
            color="primary"
            :size="24"
            :width="3"
          ></v-progress-circular>
          ...{{ $t("global.refreshing").toLowerCase() }}
        </span>
      </v-btn>
      <v-dialog v-model="dialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">
            {{ $t("visits.planned-visits") }}
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <span
                    v-for="(visit, index) in plannedShifts"
                    :key="index"
                    class="listItem"
                  >
                    {{ index + 1 }}. {{ formatPlannedVisit(visit) }}
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-title class="headline">
            {{ $t("visits.todays-completed-visits") }}
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item v-if="Object.keys(myVisits).length > 0">
                <v-list-item-content>
                  <span
                    v-for="(visit, visitId, i) in myVisits"
                    :key="visitId"
                    class="listItem"
                  >
                    {{ i + 1 }}. {{ formatVisit(visit) }}
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="error" text @click="dialog = false">
              <v-icon left>close</v-icon>{{ $t("btn.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { db, auth, analytics } from "@/main";
import { formatDate, formatTimeFromSec } from "@/helpers/helperFns.js";
import { isEmpty, isNil } from "lodash";
import { bfhShiftsToTxt, nbhShiftsToTxt } from "@/helpers/constants";
export default {
  name: "MyVisits",
  props: ["addingNewVisit", "clients", "plannedShifts", "selectedUserId"],
  data() {
    return {
      myVisits: {},
      formatDate: formatDate(new Date()),
      dialog: false,
    };
  },
  created() {
    let vm = this;
    let uid = isNil(this.selectedUserId) ? auth.currentUser.uid : this.selectedUserId;
    //uid = "5pimvxZQzKc799y1NlpAb9c4WF62"; //FOR TEST

    //start live listener
    db.collection("visits")
      .where("dateFormatted", "==", vm.formatDate)
      .where("user", "==", uid)
      .orderBy("startAt")
      .onSnapshot((visitsSnap) => {
        let myVisitsL = {};
        visitsSnap.forEach((visit) => {
          myVisitsL[visit.id] = visit.data();
        });
        vm.myVisits = Object.assign({}, myVisitsL);
      });
  },
  methods: {
    openMyVisits() {
      this.dialog = true;
      //report to analytics

      analytics.logEvent("open_my_visits", {
        user: this.$root.currentUserData.displayName,
      });
    },
    formatPlannedVisit(visitData) {
      let residence, client;
      if (!isNil(visitData.clientData)) {
        client = visitData.clientData;
      } else if (!isNil(visitData.residenceData)) {
        residence = visitData.residenceData;
      }
      const name = client ? client.clientName : residence ? residence.name : "";
      //clientServices
      const shiftName =
        residence || client.clientServices == "bfh"
          ? bfhShiftsToTxt[visitData.shiftNumber]
          : client
          ? nbhShiftsToTxt[visitData.shiftNumber]
          : "";
      let formatVisit = `${name} (${shiftName})`;
      return formatVisit;
    },
    formatVisit(visitData) {
      let formatV =
        formatTimeFromSec(visitData.startAt.seconds) +
        " " +
        visitData.clientName +
        " " +
        visitData.duration;
      return formatV;
    },
  },
};
</script>

<style>
.listItem {
  color: grey;
  padding-bottom: 5px;
}
</style>