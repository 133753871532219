<template>
  <v-alert
    prominent
    :type="incType"
    :icon="incType == 'error' ? 'warning' : 'check_circle'"
    :color="incType"
    :value="!inc.reviewReviewedByAider"
  >
    <v-row align="center">
      <v-col class="grow">
        <strong v-if="incType == 'success'"
          >{{ $t("global.confirmed").toUpperCase() }}
        </strong>
        <strong v-if="incType == 'error'"
          >{{ $t("global.rejected").toUpperCase() }}
        </strong>
        {{ inc.clientName }} - {{ inc.dateFormatted }}
        <span v-if="inc.incHours"
          >{{ $t("global.plkst(lv)").toLowerCase() }}: {{ inc.incHours }}:{{
            inc.incMinutes
          }}</span
        >
        {{ inc.incident }}
        {{ inc.comment }}
        <br />
        <small
          >{{ $t("global.reviewed-by") }}: {{ inc.reviewAddedByName }}
          {{ formatDateTime(inc.reviewdAt.seconds) }}
          <span v-if="inc.managerComment"
            >{{ $t("general.comment") }}: {{ inc.managerComment }}</span
          >
        </small>
      </v-col>
      <v-col class="shrink">
        <v-btn @click="aiderReviewed()" color="accent"
          >{{ $t("btn.close") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { db } from "@/main";

import { formatDateTime } from "@/helpers/helperFns";

export default {
  props: ["incData", "incId"],
  data() {
    return {
      incType: "error",
      inc: {},
    };
  },
  created() {
    this.inc = Object.assign({}, this.incData);
    if (this.inc.incidentStatus == "confirmed") {
      this.incType = "success";
    }
  },
  methods: {
    async aiderReviewed() {
      //confirmed
      this.inc.reviewReviewedByAiderAt = new Date();
      this.inc.reviewReviewedByAider = true;
      await db.collection("incidents").doc(this.incId).set(this.inc);
    },
    formatDateTime(sec) {
      return formatDateTime(sec);
    },
  },
};
</script>
