<template>
  <v-row>
    <v-col cols="12" class="text-center">
      <custom-button
        text="Darba grafiks"
        @click="dialogOpen = true"
        icon="edit_calendar"
      />
      <v-dialog v-model="dialogOpen" persistent max-width="800">
        <loading-data
          v-if="progressBar"
          feedbackText="Atjauno grafiku"
        ></loading-data>
        <v-card class="px-2 center">
          <v-row align="center" justify="center">
            <h1 class="py-5">Darba grafiks</h1>
          </v-row>
          <v-col
            v-for="(item, index) in daysList"
            :key="index"
            class="addTimeItem mb-3"
          >
            <v-row class="px-5" align="center">
              <h3>
                {{ $t(`general.weekdays.${item.day}`) }} ({{ item.dateKey }})
              </h3>
              <v-spacer />
              <v-checkbox
                :input-value="!item.isOffDay"
                @change="(e) => onCheckboxChange(e, item)"
                label="Strādāšu"
              />
            </v-row>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <custom-button
              type="textBtn"
              icon="save"
              :text="$t('btn.save')"
              @click="saveSchedule()"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import CustomButton from "./CustomButton.vue";
import { db } from "@/main";
import { find, findIndex, forEach, includes, isEmpty, isEqual } from "lodash";
import LoadingData from "./utilities/LoadingData.vue";
import { dayInRiga, formatDateInRiga } from "@/helpers/dayjsDates";
export default {
  components: { CustomButton, LoadingData },
  props: ["selectedUser"],
  data() {
    return {
      dialogOpen: false,
      progressBar: false,
      daysList: [],
      user: null,
    };
  },
  watch: {
    dialogOpen(val) {
      if (val) {
        this.loadSchedule();
      }
    },
    selectedUser(val) {
      this.user = val;
    },
  },
  created() {
    this.user = this.selectedUser;
  },
  methods: {
    onCheckboxChange(state, item) {
      const foundDayIndex = findIndex(this.daysList, (day) =>
        isEqual(day.day, item.day)
      );
      if (foundDayIndex > -1) {
        this.daysList[foundDayIndex].isOffDay = !state;
      }
      console.log(this.daysList);
    },
    loadSchedule() {
      this.daysList = [];
      let allDays = [];
      if (this.selectedUser && this.selectedUser.workDays) {
        allDays = this.selectedUser.workDays;
      } else {
        allDays = [
          {
            day: 1,
            isOffDay: false,
          },
          {
            day: 2,
            isOffDay: false,
          },
          {
            day: 3,
            isOffDay: false,
          },
          {
            day: 4,
            isOffDay: false,
          },
          {
            day: 5,
            isOffDay: false,
          },
          {
            day: 6,
            isOffDay: false,
          },
          {
            day: 0,
            isOffDay: false,
          },
        ];
      }
      //sort days
      const dayinRiga = dayInRiga();
      let daysNumbers = [];
      const weekDays = [1, 2, 3, 4, 5, 6, 0];
      if (dayinRiga > 0) {
        daysNumbers = Array.from(
          { length: 6 - dayinRiga },
          (v, k) => k + dayinRiga + 1
        );
        daysNumbers.push(0);
        forEach(weekDays, (day) => {
          if (!includes(daysNumbers, day) && day <= dayinRiga) {
            daysNumbers.push(day);
          }
        });
      } else {
        daysNumbers = weekDays;
      }
      let allDaysSorted = [];

      forEach(daysNumbers, (dayNumber) => {
        const foundDay = find(allDays, (day) => isEqual(day.day, dayNumber));
        let today = new Date();
        const daysToAdd =
          dayNumber == 0
            ? 7 - dayinRiga
            : dayNumber > dayinRiga
            ? dayNumber - dayinRiga
            : 7 - dayinRiga + dayNumber;
        today.setDate(today.getDate() + daysToAdd);
        foundDay.dateKey = formatDateInRiga(today);
        allDaysSorted.push(foundDay);
      });
      this.daysList = allDaysSorted;
    },
    async saveSchedule() {
      this.progressBar = true;

      var conf = confirm(
        "Jaunais darba grafiks stāsies spēkā no rītdienas! Lūdzu pārbaudiet vai rītdienas darba grafiks ir tāds pats kā iecerēts!"
      );
      if (conf) {
        const daysListFormatted = this.daysList.map((day) => {
          return {
            day: parseInt(day.day),
            startHours: 0,
            startMinutes: 0,
            endHours: 23,
            endMinutes: 59,
            isOffDay: day.isOffDay,
          };
        });
        await db
          .collection("users")
          .doc(this.user.userId)
          .update({ workDays: daysListFormatted });
        this.selectedUser.workDays = daysListFormatted;
        this.dialogOpen = false;
      }
      this.progressBar = false;
    },
  },
};
</script>

<style scoped>
.addTimeItem {
  border: 2px solid black;
  padding-bottom: 20px;
}
</style>