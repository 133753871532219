<template>
  <v-row>
    <v-col cols="12" class="text-center" v-if="updatingSession == false">
      <v-btn
        color="primary"
        v-if="myVisitSession.visitOn == false"
        @click.stop="startVisitDialog()"
        :disabled="showFeedback || !isSelectedCurrentUser()"
      >
        <v-icon left>play_arrow</v-icon>
        {{ $t("btn.start").toUpperCase() }}
      </v-btn>
      <span v-else>
        <v-btn
          color="primary"
          @click="endVisit()"
          :disabled="showFeedback || isStopButtonDisabled"
        >
          <span>
            <v-icon left>stop</v-icon>
            {{ $t("btn.stop").toUpperCase() }} {{ visitTime }}
          </span>
        </v-btn>
      </span>
    </v-col>

    <!-- VISIT DIALOG -->
    <v-dialog v-model="visitDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">{{
          $t("visits.clients-nearby")
        }}</v-card-title>
        <v-card-subtitle v-if="showAllClients">
          <br />
          <v-alert class="error">
            {{ $t("visits.gps-error-choose-client") }}
          </v-alert>
        </v-card-subtitle>
        <v-card-text>
          <v-radio-group v-model="clientIndex">
            <v-radio
              v-for="(client, clientId) in clientsInRange"
              :key="clientId"
              :label="client.clientName"
              :value="clientId"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            text
            outlined
            @click.stop="cancelVisitDialog()"
            >{{ $t("btn.cancel") }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="clientIndex != null"
            color="success"
            @click="startVisit()"
          >
            <v-icon left>play_arrow</v-icon>{{ $t("btn.start") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- loading data feedback -->
    <loading-data
      v-if="showFeedback"
      :feedbackText="feedbackText"
    ></loading-data>
  </v-row>
</template>

<script>
//helpers
import { getClockFromMs, calculateVisitDuration } from "@/helpers/helperFns.js";

import {
  formatDateInRiga,
  isoDateInRiga,
  monthKeyInRiga,
} from "@/helpers/dayjsDates.js";

import LoadingData from "@/components/utilities/LoadingData.vue";
import { db, auth } from "@/main";
import firebase from "firebase/app";
import "firebase/firestore";
import { i18n } from "@/language/i18n";
import { isEqual } from "lodash";

export default {
  name: "log-visits",
  props: ["clients", "selectedUser"],
  components: {
    LoadingData,
  },
  data: () => ({
    clientsInRange: {},
    maxDistanceFromClient: 300, //meters from client address
    visitDialog: false,
    clientIndex: null,
    visitUserPosition: {
      lat: 56.9582706, //default location Baznīcas 24, Rīga
      lng: 24.1211909,
    },
    myVisitSession: {
      visitOn: false,
    },
    showFeedback: false,

    updatingSession: false,
    timer: null,
    isStopButtonDisabled: true,
    visitTime: null,
    showAllClients: false,
    feedbackText: i18n.t("visits.searching-gps-location"),
  }),
  // computed: {
  //   feedbackText() {
  //     return this.$t("visits.searching-gps-location");
  //   },
  // },
  async created() {
    let vm = this;
    //get session data
    this.getVisitSession();
    //initialize gps position
    let position = await this.getPosition(10000, 0); //wait 10 sec, age 0 ms
    if (position) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      const accuracy = position.coords.accuracy.toFixed(0);
      const t = position.timestamp;

      //log coordinate
      const activity = `GPS INIT location lat: ${lat} lng: ${lng}, accuracy ${accuracy} t: ${getClockFromMs(
        t
      )}`;
      vm.logActivity("GPS", vm.$root.currentUserData.displayName, activity);
    }
    if (this.myVisitSession.visitOn) {
      this.startTimer();
    }
  },
  watch: {
    "myVisitSession.visitOn": function (val) {
      if (!val) {
        return null;
      }

      if (val == true) {
        this.startTimer();
      }
    },
  },
  methods: {
    isSelectedCurrentUser() {
      return isEqual(
        this.$root.currentUserData.userId,
        this.selectedUser.userId
      );
    },
    getPosition(timeout, age) {
      if (navigator.geolocation) {
        //https://stackoverflow.com/a/31916631/2617776
        //dummy
        navigator.geolocation.getCurrentPosition(
          function () {},
          function () {},
          {}
        );
        //real
        const options = {
          enableHighAccuracy: true,
          timeout: timeout, //amount of time before the error callback is invoked, if 0 it will never invoke. https://stackoverflow.com/a/57669779/2617776
          maximumAge: age, //integer (milliseconds) | infinity - maximum cached position age.
        };
        // Promise wrapper
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            resolve,
            ({ code, message }) => reject(this.gpsFail(code, message)),
            options
          );
        });
      }
    },
    gpsSuccess(position) {
      let vm = this;
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      const t = position.timestamp;
      vm.visitUserPosition = Object.assign({}, { lat: lat, lng: lng });
    },
    gpsFail(code, message) {
      let vm = this;
      //log gps error
      const activity = "GPS ERR " + code + " - " + message;
      vm.logActivity("GPS", vm.$root.currentUserData.displayName, activity);
    },
    async startVisitDialog() {
      let vm = this;
      vm.isStopButtonDisabled = true;
      vm.showAllClients = false;
      vm.userFeedback(true, vm.feedbackText);
      //get coordinates
      try {
        const maxAge = 60000; // age 1 minute
        const gpsTimeout = 5000; //wait 5 sec,
        let position = await this.getPosition(gpsTimeout, maxAge);

        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        const accuracy = position.coords.accuracy.toFixed(0);
        const t = position.timestamp;
        vm.visitUserPosition = Object.assign({}, { lat: lat, lng: lng });

        //show clients in range
        let cachedPos = false;
        const posAge = new Date().getTime() - t;

        if (posAge > maxAge) {
          //show all my active clients
          vm.showAllClients = true;
          cachedPos = true;
          vm.getClientsInRange("showAll");
        } else {
          vm.getClientsInRange(position);
        }

        //log coordinate
        const activity = `GPS OPEN DIALOG lat: ${lat} lng: ${lng}, accuracy ${accuracy}, t: ${getClockFromMs(
          t
        )} cached: ${cachedPos}, PosAge: ${posAge}, showAllClients: ${
          vm.showAllClients
        }`;
        vm.logActivity("GPS", vm.$root.currentUserData.displayName, activity);
      } catch (e) {
        //show all my active clients
        vm.showAllClients = true;
        vm.getClientsInRange("showAll");
      } //end if gps error

      vm.userFeedback(false);
    },
    async endVisit() {
      var vm = this;
      const clientName = vm.myVisitSession.clientName;
      var conf = confirm(
        `${this.$t("visits.visit-finish-confirmation")} ${clientName}?`
      );
      if (conf) {
        vm.$emit("savingVisit", true);
        vm.userFeedback(true, this.$t("finishing-visit"));
        const startAt = new Date(vm.myVisitSession.startAtIso);
        const endAt = new Date();
        const duration = calculateVisitDuration(startAt, endAt);
        //save visit data
        //save in db
        vm.myVisitSession.endAt = endAt;
        vm.myVisitSession.duration = duration;
        vm.myVisitSession.visitOn = false;
        if (isEqual(vm.myVisitSession.clientService, "mokLit")) {
          vm.myVisitSession.service = "mokLit";
        }
        db.collection("visits")
          .doc("userVisitSessions")
          .update({
            [this.selectedUser.userId]: vm.myVisitSession,
          });

        let addVisit = db.collection("visits").add(vm.myVisitSession);

        //show locally
        vm.myVisitSession = Object.assign({}, vm.myVisitSession);

        //log activity
        const activity = "END at " + vm.myVisitSession.clientName;
        vm.logActivity(
          "VISITS",
          vm.$root.currentUserData.displayName,
          activity
        );

        vm.userFeedback(false);
        vm.cancelVisitDialog();
        await addVisit;
        vm.$emit("savingVisit", false);
      }
    },
    async startVisit() {
      var vm = this;
      const clientName = vm.clients[vm.clientIndex].clientName;
      const clientService = vm.clients[vm.clientIndex].clientServices;
      var conf = confirm(
        `${this.$t("visits.visit-start-confirmation")} ${clientName}?`
      );
      if (conf) {
        vm.userFeedback(true, this.$t("visits.starting-visit"));
        const visitLocation = new firebase.firestore.GeoPoint(
          vm.visitUserPosition.lat,
          vm.visitUserPosition.lng
        );

        const startAt = new Date();

        let visitSessionData = {
          visitOn: true,
          clientId: vm.clients[vm.clientIndex].id,
          clientName: clientName,
          clientService: clientService,
          location: visitLocation,
          startAt: startAt,
          startAtIso: startAt.toISOString(),
          dateFormatted: formatDateInRiga(startAt),
          monthKey: monthKeyInRiga(startAt),
          user: this.selectedUser.userId,
          userName: this.$root.currentUserData.displayName,
          showAllClients: vm.showAllClients,
        };

        //save session in db
        db.collection("visits")
          .doc("userVisitSessions")
          .update({
            [this.selectedUser.userId]: visitSessionData,
          });
        //show locally
        vm.myVisitSession = Object.assign({}, visitSessionData);

        //log activity
        const activity =
          "START at " + clientName + ". ShowAll: " + vm.showAllClients;
        vm.logActivity(
          "VISITS",
          vm.$root.currentUserData.displayName,
          activity
        );

        //close dialog
        vm.userFeedback(false);
        vm.cancelVisitDialog();
      }
    },
    async getVisitSession() {
      this.updatingSession = true;
      let visitSession = await db
        .collection("visits")
        .doc("userVisitSessions")
        .get();

      if (visitSession.exists) {
        let vs = visitSession.data();
        if (vs[this.selectedUser.userId]) {
          this.myVisitSession = Object.assign({}, vs[this.selectedUser.userId]);
        }
      }

      this.updatingSession = false;
    },

    startTimer() {
      var vm = this;
      vm.timer = setInterval(() => {
        if (vm.myVisitSession.visitOn == true) {
          //start timer
          const now = new Date().getTime();
          const start = new Date(vm.myVisitSession.startAtIso).getTime();
          const distance = now - start;
          var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          var timeoutInSeconds = 1000 * 60 * 15;
          if (distance > timeoutInSeconds) {
            vm.isStopButtonDisabled = false;
          }
          vm.visitTime =
            hours +
            ":" +
            (minutes < 10 ? "0" + minutes : minutes) +
            ":" +
            (seconds < 10 ? "0" + seconds : seconds);
        } else {
          //end timer
          clearTimeout(vm.timer);
          vm.timer = null;
          vm.visitTime = null;
        }
      }, 1000);
    },
    getClientsInRange(position) {
      var vm = this;
      //show clients active, neighborhood, and in range

      let clientsInRangeL = {};
      //loop clients
      Object.keys(vm.clients).forEach((clId) => {
        let c = vm.clients[clId];
        if (c.clientStatus == "active") {
          //calculate distance
          if (position !== "showAll") {
            if (typeof c.clientAddressCoordinates != "undefined") {
              var distanceFromUser = vm.calculateDistance(
                c.clientAddressCoordinates.lat,
                c.clientAddressCoordinates.lng,
                position.coords.latitude,
                position.coords.longitude
              );
              if (distanceFromUser < vm.maxDistanceFromClient) {
                //if in range
                clientsInRangeL[clId] = c;
              }
            } //end if client has coordinates
          } else {
            //show all
            clientsInRangeL[clId] = c;
          }
        } //end if active client
      }); //end loop clients
      vm.clientsInRange = Object.assign({}, clientsInRangeL);
      vm.visitDialog = true;
    },
    cancelVisitDialog() {
      var vm = this;
      //clear data
      vm.visitDialog = false;
      vm.clientIndex = null;
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      //https://stackoverflow.com/questions/639695/how-to-convert-latitude-or-longitude-to-meters
      // generally used geo measurement function
      var R = 6378.137; // Radius of earth in KM
      var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
      var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
          Math.cos((lat2 * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d * 1000; // meters
    },
    userFeedback(status, text) {
      this.showFeedback = status;
      this.feedbackText = text;
    },
  },
};
</script>
