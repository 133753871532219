<template>
  <v-container>
    <v-card max-width="500" class="mx-auto">
      <!-- Alserts and notifications -->
      <v-alert
        :type="attestationPromptType"
        dismissible
        v-if="attestationPromptType"
        @input="attestationPromptType = null"
      >
        {{ attestationPromptText }}
      </v-alert>

      <alert-for-survey
        :userData="userData"
        @updateSurveyData="updateSurveyData"
      />

      <v-col p="3">
        <view-as-user-selection @onUserSelection="onUserSelection" />
      </v-col>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1>{{ $t("tasks.my-today") }}</h1>
          <h3>{{ formatDate }} - {{ dayTxt }}</h3>
        </v-col>
      </v-row>
      <v-col cols="12" class="text-center" v-if="!loadingTasks">
        <accept-tomorrows-tasks-dialog-button :employeeData="selectedUser" />
      </v-col>
      <!-- incidents for review -->
      <template v-if="Object.keys(incidentsForReview).length > 0">
        <v-row v-for="(inc, incId) in incidentsForReview" :key="incId">
          <v-col cols="10" class="mx-auto">
            <review-alert :incId="incId" :incData="inc"></review-alert>
          </v-col>
        </v-row>
      </template>

      <!-- no coordinates error -->
      <v-row v-if="noCoordinateClients.length > 0">
        <v-col cols="10" class="mx-auto">
          <v-alert :value="true" type="error">
            {{ $t("general.missing-addreses-coordinates") }}:
            <ul>
              <li v-for="(client, index) in noCoordinateClients" :key="index">
                <v-icon
                  color="error"
                  v-if="
                    client.hasRecentHealthProblems != null &&
                    client.hasRecentHealthProblems
                  "
                  >warning</v-icon
                >
                {{ client.clientName }}
              </li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>

      <!-- aider alerts if any residents have recent events-->
      <template v-if="Object.keys(aiderAlerts).length > 0">
        <v-row v-for="(a, aId) in aiderAlerts" :key="aId">
          <v-col cols="10" class="mx-auto">
            <v-alert dense type="error">
              <strong>{{ a.clientName }}</strong>
              {{ $t("client.pay-attention").toLowerCase() }}
              {{ $t("client.recent-incident-registred") }}:
              <strong>{{ a.incident }}</strong
              >.
            </v-alert>
          </v-col>
        </v-row>
      </template>
      <!-- visits -->
      <template v-if="service != 'bfh' && !loadingTasks && selectedUser">
        <log-visits
          :clients="clients"
          @savingVisit="savingVisit"
          :selectedUser="selectedUser"
        ></log-visits>
        <my-visits
          :addingNewVisit="addingNewVisit"
          :clients="clients"
          :plannedShifts="getAllVisits()"
          :selectedUserId="selectedUser.userId"
        ></my-visits>
      </template>

      <!-- tasks -->
      <v-card-text v-if="haveTasks == true">
        <template>
          <h2>{{ $t("tasks.tasks") }}</h2>
          <v-switch
            v-model="showAlsoCompletedTasks"
            inset
            @change="toggleClosed()"
            :label="$t('tasks.closed')"
          ></v-switch>
          <virtual-list
            :data-key="'id'"
            :data-sources="taskItemList"
            :data-component="itemComponent"
            :page-mode="true"
            :estimate-size="500"
          />
        </template>
      </v-card-text>

      <!--Progress bar -->
      <v-row no-gutters>
        <v-col cols="12" v-if="loadingTasks">
          <v-progress-linear :indeterminate="true"></v-progress-linear>
        </v-col>
      </v-row>

      <!-- doesnt have tasks -->
      <v-card-title class="headline" v-if="haveTasks == false && !loadingTasks">
        <v-row justify="center">
          {{ $t("tasks.no-tasks-today") }}
        </v-row>
      </v-card-title>
      <v-row justify="center" v-if="haveTasks == false && !loadingTasks">
        <custom-button
          @click="refreshBrowser"
          :text="$t('btn.refresh-browser')"
          icon="refresh"
          dynamicClass="my-4"
        />
      </v-row>
    </v-card>

    <!-- loading data feedback -->
    <loading-data
      v-if="loadingTasks"
      :feedbackText="$t('tasks.load-tasks')"
    ></loading-data>

    <!-- one task dialog -->
    <one-task-dialog
      :dialogTaskData="dialogTaskData"
      :dialogTaskId="dialogTaskId"
      :showDialog="dialogTaskShow"
      :source="dialogTaskSource"
      :index="dialogTaskIndex"
      @closeTaskDialog="dialogTaskShow = false"
      @updTaskFromDialog="updTaskFromDialog"
    >
    </one-task-dialog>
  </v-container>
</template>

<script>
import { db, auth, functions } from "@/main";

//Task list
//https://tangbc.github.io/vue-virtual-scroll-list/#/keep-state
import TaskTile from "@/views/mytasks/TaskTile.vue";
import VirtualList from "vue-virtual-scroll-list";
import AlertForSurvey from "@/components/AlertForSurvey.vue";

//helpers
import {
  daysToTxt,
  nbhShiftsToTxt,
  bfhShiftsToTxt,
  repositioningTaskId,
} from "@/helpers/constants";
import {
  formatDateInRiga,
  formatDateTimeInRiga,
} from "@/helpers/dayjsDates.js";

//components
import ReviewAlert from "@/components/utilities/ReviewIncidentAlert.vue";
import LoadingData from "@/components/utilities/LoadingData.vue";
import LogVisits from "@/views/mytasks/Visits.vue";
import OneTaskDialog from "@/views/mytasks/OneTaskDialog.vue";
import MyVisits from "@/views/mytasks/MyVisits.vue";
import { debounce } from "debounce";
import {
  isEqual,
  forEach,
  find,
  isNil,
  includes,
  isEmpty,
  split,
  findIndex,
  filter,
  concat,
} from "lodash";
import ViewAsUserSelection from "@/components/ViewAsUserSelection.vue";
import CustomButton from "@/components/CustomButton.vue";
import {
  getDaysBetween,
  timestampToDate,
} from "@/helpers/dateTimeConvertingFunctions";
import EmployeeWorkTimesDialog from "@/components/EmployeeWorkTimesDialog.vue";
import EmployeeWorkDaysDialog from "@/components/EmployeeWorkDaysDialog.vue";
import AcceptTomorrowsTasksDialogButton from "@/components/AcceptTomorrowsTasksDialogButton.vue";

export default {
  name: "my-tasks",
  components: {
    "virtual-list": VirtualList,
    ReviewAlert,
    LoadingData,
    LogVisits,
    OneTaskDialog,
    MyVisits,
    ViewAsUserSelection,
    CustomButton,
    EmployeeWorkTimesDialog,
    EmployeeWorkDaysDialog,
    AlertForSurvey,
    AcceptTomorrowsTasksDialogButton,
  },
  data: () => ({
    //get tasks setup
    showAlsoCompletedTasks: false,
    service: "bfh",
    formatDate: formatDateInRiga(new Date()),
    haveTasks: false,
    //load while scrolling
    loadingTasks: false,
    itemComponent: TaskTile,
    //data
    myTasks: {},
    taskItemList: [],
    clients: {},
    aiderAlerts: {},
    alertsForClients: [],
    //user feedback
    incidentsForReview: {},
    //one dialog
    dialogTaskData: {},
    dialogTaskSource: {},
    dialogTaskId: "",
    dialogTaskShow: false,
    dialogTaskIndex: 0,
    //saving visit?
    addingNewVisit: false,
    noCoordinateClients: [],
    monthKey: new Date().toISOString().substr(0, 7),
    todaysKey: new Date().toISOString().substr(0, 10),
    allTasks: [],
    allShifts: [],
    todaysShifts: [],
    todaysClients: [],
    userData: {},
    todaysShiftsList: [],
    showOnlyOpenTasks: true,
    residences: [],
    viewAsUser: "",
    selectedUser: {},
    attestationPromptType: null,
    attestationPromptText: "",
  }),
  computed: {
    dayTxt() {
      const translatedDays = daysToTxt();
      return translatedDays[new Date().getDay()];
    },
  },
  async created() {
    await this.selectInitialUser();
    this.loadAllShiftsData();

    // detecting change checked value from item component event.
    // receive: from tile open/close dialog
    this.$on("openDialog", (taskId, source, index) => {
      this.dialogTaskSource = Object.assign({}, source);
      const foundTask = find(this.allTasks, (task) => isEqual(task.id, taskId));
      this.dialogTaskData = foundTask;
      this.dialogTaskId = taskId;
      this.dialogTaskIndex = index;
      this.dialogTaskShow = true;
    });
  },
  methods: {
    async updateSurveyData(surveyKey) {
      //save in db
      if (auth.currentUser) {
        const timestamp = new Date();
        const survLink = `surveys.${surveyKey}`;
        const updObj = { [survLink]: timestamp };
        await db.collection("users").doc(auth.currentUser.uid).update(updObj);

        if (!this.userData.surveys) {
          this.userData.surveys = {};
        }
        this.userData.surveys[surveyKey] = timestamp;
        this.userData = Object.assign({}, this.userData);
      }
    },
    onTaxesAlertClick() {
      this.$router.push("yourtaxes");
    },
    async showAttestationAlert() {
      const attestationResponse = await db
        .collection("attestations")
        .where("userId", "==", this.selectedUser.userId)
        .where("isActive", "==", true)
        .get();
      if (!attestationResponse.empty) {
        let attestationData = attestationResponse.docs[0].data();
        if (_.isNil(attestationData.finishedDate)) {
          const startedDate = timestampToDate(attestationData.startedDate);
          const daysLeft = 30 - getDaysBetween(new Date(), startedDate);
          if (daysLeft <= 10 && daysLeft > 0) {
            this.attestationPromptType = "error";
          } else if (daysLeft > 10) {
            this.attestationPromptType = "warning";
          } else {
            this.attestationPromptType = null;
          }
          this.attestationPromptText = `${this.$t(
            "attestation.attestation-prompt-part-1"
          )} ${daysLeft} ${this.$t("attestation.attestation-prompt-part-2")}`;
        } else {
          this.attestationPromptType = null;
        }
      } else {
        this.attestationPromptType = null;
      }
    },
    refreshBrowser() {
      window.location.reload();
    },
    async loadAllShiftsData() {
      this.loadingTasks = true;

      let prep = functions.httpsCallable("getTodaysShiftsData");
      let request = {
        userId: this.selectedUser.userId,
        aiderServices: this.selectedUser.aiderServices,
      };
      let response = await prep(request);
      if (!isNil(response) && !isNil(response.data)) {
        const data = response.data;
        this.todaysClients = data.todaysClients || [];
        this.todaysShifts = data.allShifts || [];
        this.service = data.service || "bfh";
        this.clients = data.todaysClients || [];
        this.noCoordinateClients = data.noCoordinateClients || [];
        this.allTasks = data.allTasks || [];
        await this.createShiftsItems();
      }
      this.loadingTasks = false;
      await this.getAiderAlerts();
      await this.getReviewedIncidents();
      await this.showAttestationAlert();
    },

    async selectInitialUser() {
      if (!isNil(this.$root.currentUserData)) {
        this.selectedUser = this.$root.currentUserData;
        await this.getUserInfo();
      }
    },
    async onUserSelection(user) {
      const vm = this;
      if (!isNil(user)) {
        vm.selectedUser = user;
        vm.selectedUser.userId = user.id;
        await this.getUserInfo();
        await vm.loadAllShiftsData();
      }
    },
    savingVisit(status) {
      this.addingNewVisit = status;
    },
    async updTaskFromDialog(taskId, field, newValue) {
      let vm = this;
      let foundTaskIndex = findIndex(this.allTasks, (task) =>
        isEqual(task.id, taskId)
      );
      const taskItemIndex = findIndex(vm.taskItemList, (item) =>
        isEqual(item.id, taskId)
      );
      if (foundTaskIndex > -1) {
        vm.allTasks[foundTaskIndex][`${field}`] = newValue;
      }
      if (taskItemIndex > -1) {
        vm.taskItemList[taskItemIndex].data[`${field}`] = newValue;
        vm.createShiftsItems();
      }
    },
    async getReviewedIncidents() {
      let vm = this;
      let getI = await db
        .collection("incidents")
        .where("createdBy", "==", this.selectedUser.userId)
        .where("reviewReviewedByAider", "==", false)
        .orderBy("updatedAt")
        .get();

      //unreviewed reviewed incidents
      let incidentsForReviewL = {};
      if (getI.size > 0) {
        getI.forEach((i) => {
          incidentsForReviewL[i.id] = i.data();
        });
      }
      vm.incidentsForReview = Object.assign({}, incidentsForReviewL);
    },
    toggleClosed() {
      var vm = this;
      vm.showOnlyOpenTasks = !vm.showOnlyOpenTasks;
      this.createShiftsItems();
    },
    getAiderAlerts: debounce(async function () {
      let vm = this;
      //loop clients
      vm.aiderAlerts = {};
      let aiderAlertsL = {};
      for await (const client of vm.todaysClients) {
        const clientId = client.id;
        if (vm.alertsForClients.indexOf(clientId) == -1 && client.clientName) {
          let alertResponse = await db
            .collection("alertAiders")
            .where("clientId", "==", clientId)
            .get();

          forEach(alertResponse.docs, (alert) => {
            const data = alert.data();
            if (data) {
              aiderAlertsL[alert.id] = data;
            }
          });
        }
      }
      if (Object.keys(aiderAlertsL).length > 0) {
        vm.aiderAlerts = aiderAlertsL;
      }
    }, 1000),

    async getTodaysShifts() {
      const services = ["neighborhood", "mokLit"];
      let myShifts = [];
      for await (const service of services) {
        const key = `${service}-${this.monthKey}`;
        let getShiftsByService = await db
          .collection("shiftPlanning")
          .doc(key)
          .get();
        if (getShiftsByService.exists) {
          const shiftsList = getShiftsByService.data();
          Object.entries(shiftsList).map((shift) => {
            if (
              isEqual(this.selectedUser.userId, shift[1]) &&
              shift[0].includes(this.todaysKey)
            ) {
              const shiftName = shift[0];
              const clientId = shiftName.substring(0, shiftName.length - 13);
              const shiftNumber = shiftName.substring(shiftName.length - 1);
              myShifts.push({
                shiftName: shiftName || null,
                clientId: clientId || null,
                shiftNumber: shiftNumber || null,
              });
            }
          });
        }
      }
      myShifts.sort((a, b) => (a.shiftNumber > b.shiftNumber ? 1 : -1));
      this.allShifts = myShifts;
    },
    getPlannedShifts() {
      let myShifts = [];
      this.service = "bfh";
      forEach(this.allShifts, (shift) => {
        let client, residence;
        if (!isNil(shift.clientId)) {
          client = find(this.todaysClients, (client) =>
            isEqual(client.id, shift.clientId)
          );
        } else if (!isNil(shift.residenceId)) {
          residence = find(this.residences, (residence) =>
            isEqual(residence.id, shift.residenceId)
          );
        }
        if (!isNil(client)) {
          if (
            isEqual(this.service, "bfh") &&
            !isEqual(client.clientServices, "bfh")
          ) {
            this.service = client.clientServices;
          }
          const clientInTodaysClientsList = find(
            this.todaysClients,
            (foundClient) => isEqual(foundClient.id, client.clientId)
          );
          if (isNil(clientInTodaysClientsList)) {
            this.todaysClients.push(client);
            const foundClientInNoCoordinatesList = find(
              this.noCoordinateClients,
              (foundClient) => isEqual(foundClient.id, client.id)
            );
            if (
              isNil(client.clientAddressCoordinates) &&
              isNil(foundClientInNoCoordinatesList) &&
              !isEqual(client.clientServices, "bfh")
            ) {
              this.noCoordinateClients.push(client);
            }
          }
        }
        const shiftTasks = [];
        forEach(this.allTasks, (task) => {
          if (isEqual(task.clientShiftKeyNew, shift.shiftName)) {
            shiftTasks.push(task);
          }
        });
        const foundShift = find(myShifts, (foundShift) =>
          isEqual(foundShift.shiftNumber, shift.shiftNumber)
        );
        if (isNil(foundShift)) {
          myShifts.push({
            shiftNumber: shift.shiftNumber,
            shiftsList: [
              {
                clientId: shift.clientId ? shift.clientId : null,
                residenceId: shift.residenceId ? shift.residenceId : null,
                clientData: client ? client : null,
                residenceData: residence ? residence : null,
                shiftTasks: shiftTasks,
                shiftNumber: shift.shiftNumber,
              },
            ],
          });
        } else {
          foundShift.shiftsList.push({
            clientId: shift.clientId ? shift.clientId : null,
            residenceId: shift.residenceId ? shift.residenceId : null,
            clientData: client ? client : null,
            residenceData: residence ? residence : null,
            shiftTasks: shiftTasks,
            shiftNumber: shift.shiftNumber,
          });
          // sort shiftsList by tasks count
          foundShift.shiftsList.sort((a, b) =>
            a.shiftTasks.length < b.shiftTasks.length ? 1 : -1
          );
        }
      });
      this.todaysShifts = myShifts;
    },
    async getClients() {
      let clientsResponse = await db
        .collection("clients")
        .orderBy("clientName")
        .get();
      let newClients = [];
      forEach(clientsResponse.docs, (doc) => {
        let data = doc.data();
        data.id = doc.id;
        newClients.push(data);
      });
      this.clients = newClients;
    },
    async getAllTasks() {
      const userId = this.selectedUser.userId;
      const tasksResponse = await db
        .collection("taskArchive")
        .where("lpTaskFormatDate", "==", this.formatDate)
        .where("responsible", "==", userId)
        .orderBy("lpTaskDate")
        .orderBy("lpTaskShiftNumber")
        .orderBy("clientName")
        .get();
      const tasksList = [];

      forEach(tasksResponse.docs, (doc) => {
        let data = doc.data();
        data.id = doc.id;
        tasksList.push(data);
      });
      for await (const taskItem of tasksList) {
        const [clientId, year, month, date, shiftNumber, taskId] = split(
          taskItem.id.toString(),
          "-",
          6
        );
        const taskDescription = await db
          .collection("neighborhoodTasks")
          .doc(taskId)
          .get();
        taskItem.taskDescription = taskDescription.data();
      }
      this.allTasks = tasksList;
    },
    async getUserInfo() {
      let userPromise = await db
        .collection("users")
        .doc(this.selectedUser.userId)
        .get();
      if (userPromise.exists) {
        this.userData = userPromise.data();
      }
    },
    createShiftsItems() {
      let vm = this;
      vm.haveTasks = false;
      vm.taskItemList = [];
      // this.getPlannedShifts();
      let listL = [];
      //LOOP SHIFTS
      forEach(this.todaysShifts, (shift) => {
        const shiftName = isEqual(this.service, "bfh")
          ? bfhShiftsToTxt[shift.shiftNumber]
          : nbhShiftsToTxt[shift.shiftNumber];
        listL.push({
          type: "shift-title",
          data: shiftName,
          id: shiftName,
        });
        forEach(shift.shiftsList, (shiftListItem) => {
          const dataItem = !isNil(shiftListItem.clientData)
            ? shiftListItem.clientData
            : !isNil(shiftListItem.residenceData)
            ? shiftListItem.residenceData
            : {};
          const name = dataItem.clientName || dataItem.name || "";
          listL.push({
            type: "client-title",
            data: name,
            id: shift.shiftNumber + "-" + dataItem.id,
          });
          if (isEmpty(shiftListItem.shiftTasks)) {
            listL.push({
              type: "no-tasks",
              id: `${shift.shiftNumber}-${dataItem.id}-empty`,
            });
          }
          let completedTasksCount = 0;

          let repositioningTasks = filter(shiftListItem.shiftTasks, (task) =>
            isEqual(task.lpTaskId, repositioningTaskId)
          );
          forEach(repositioningTasks, (task) => {
            const hours = task.order.substring(0, 2);
            const minutes = task.order.substring(3, 5);
            const [day, month, year] = split(task.lpTaskFormatDate, ".");
            const taskCreatedDate = new Date(
              year,
              month - 1,
              day,
              hours,
              minutes
            );
            task.taskCreatedDate = taskCreatedDate;
          });
          repositioningTasks.sort(function (a, b) {
            return new Date(a.taskCreatedDate) - new Date(b.taskCreatedDate);
          });

          const regularTasks = filter(
            shiftListItem.shiftTasks,
            (task) => !isEqual(task.lpTaskId, repositioningTaskId)
          );
          const allTasksList = concat(repositioningTasks, regularTasks);
          forEach(allTasksList, (task) => {
            if (task.status !== "open") {
              completedTasksCount = completedTasksCount + 1;
            }
          });
          if (
            isEqual(allTasksList.length, completedTasksCount) &&
            this.showOnlyOpenTasks &&
            allTasksList.length > 0
          ) {
            listL.push({
              type: "all-tasks-completed",
              id: `${shift.shiftNumber}-${dataItem.id}-completed`,
            });
          } else {
            forEach(allTasksList, (task) => {
              if (isEqual(task.status, "open") || !this.showOnlyOpenTasks) {
                listL.push({
                  type: "task",
                  data: task,
                  id: task.id,
                  userData: this.userData || null,
                  taskDescription: task.taskDescription || null,
                  viewDialog: false,
                  shiftName: nbhShiftsToTxt[shift.shiftNumber],
                });
              }
            });
          }
        });
      });
      //need if statement
      if (!isEmpty(this.todaysShifts)) {
        vm.haveTasks = true;
      }
      vm.taskItemList = listL;
    },
    async getBfhShifts() {
      let residencesIds = [];
      forEach(this.allTasks, (task) => {
        if (isEqual(task.service, "bfh")) {
          const [date, month, year] = split(task.lpTaskFormatDate, ".");
          const shiftNumber = task.lpTaskShiftNumber;
          const clientId = task.clientId;
          const foundShift = find(this.allShifts, (shift) =>
            isEqual(
              shift.shiftName,
              `${clientId}-${year}-${month}-${date}-${shiftNumber}`
            )
          );
          task.clientShiftKeyNew = `${clientId}-${year}-${month}-${date}-${shiftNumber}`;
          if (isEqual(task.lpTaskType, "Mājas")) {
            residencesIds.push(clientId);
            if (isNil(foundShift)) {
              this.allShifts.push({
                shiftName: `${clientId}-${year}-${month}-${date}-${shiftNumber}`,
                residenceId: clientId || null,
                shiftNumber: shiftNumber || null,
              });
            }
          } else {
            if (isNil(foundShift)) {
              this.allShifts.push({
                shiftName: `${clientId}-${year}-${month}-${date}-${shiftNumber}`,
                clientId: clientId || null,
                shiftNumber: shiftNumber || null,
              });
            }
          }
        }
      });
      if (!isEmpty(residencesIds)) {
        await this.getResidences(residencesIds);
      }
    },
    async getResidences(residencesIds = []) {
      let residencesPromise = await db
        .collection("residences")
        .where("service", "==", "bfh")
        .get();
      const residencesList = [];
      forEach(residencesPromise.docs, (doc) => {
        let data = doc.data();
        data.id = doc.id;
        if (isEmpty(residencesIds)) {
          residencesList.push(data);
        } else if (
          !isEmpty(residencesIds) &&
          includes(residencesIds, data.id)
        ) {
          residencesList.push(data);
        }
      });
      this.residences = residencesList;
    },
    getAllVisits() {
      let visitsList = [];
      forEach(this.todaysShifts, (shift) => {
        forEach(shift.shiftsList, (shiftItem) => {
          visitsList.push(shiftItem);
        });
      });
      return visitsList;
    },
  },
};
</script>
