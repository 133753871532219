<template>
  <v-list-item>
    <!-- img -->
    <v-list-item-avatar
      v-if="currPhotoUrl != '' && typeof currPhotoUrl != 'undefined'"
    >
      <img :src="currPhotoUrl" />
    </v-list-item-avatar>
    <!-- quick photo -->
    <v-list-item-content>
      <div
        class="upload-wrap"
        v-if="currPhotoUrl == '' || typeof currPhotoUrl == 'undefined'"
      >
        <v-progress-circular
          :value="progressValue"
          v-if="fileUploading"
          color="success"
          >{{ progressValue }}</v-progress-circular
        >
        <span v-if="!fileUploading">
          <input
            type="file"
            accept="image/*"
            id="photoFile"
            @change="addPhoto()"
          />
        </span>
      </div>
    </v-list-item-content>
    <!-- select photo -->
    <v-list-item-action @click.stop="photoDialog = true">
      <!-- view photo -->
      <v-btn
        icon
        v-if="currPhotoUrl != '' && typeof currPhotoUrl != 'undefined'"
      >
        <v-icon>remove_red_eye</v-icon>
      </v-btn>
    </v-list-item-action>

    <!-- photo dialog -->
    <v-dialog v-model="photoDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">{{ $t("global.photo") }}</v-card-title>

        <v-card-text>
          <!-- view photo -->
          <div v-if="currPhotoUrl != '' && typeof currPhotoUrl != 'undefined'">
            <v-img :src="currPhotoUrl"></v-img>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey" text small outlined @click="photoDialog = false">
            <v-icon left>close</v-icon>{{ $t("btn.cancel") }}
          </v-btn>
          <!-- delete photo -->
          <v-btn
            color="error"
            text
            small
            outlined
            @click="deletePhoto()"
            v-if="currPhotoUrl != '' && typeof currPhotoUrl != 'undefined'"
          >
            <v-icon left>delete_forever</v-icon>{{ $t("btn.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <loading-data
      v-if="fileUploading"
      :feedbackText="$t('global.uploading-photo')"
    ></loading-data>
  </v-list-item>
</template>

<script>
import { db, storage } from "@/main";
import LoadingData from "@/components/utilities/LoadingData.vue";

export default {
  components: { LoadingData },
  props: ["taskDataInDialog", "taskId"],
  data() {
    return {
      fileUploading: false,
      progressValue: 0,
      taskDataInQuickPhoto: {},
      placeholderPhotoUrl: "https://via.placeholder.com/150",
      //photo dialog
      photoDialog: false,
    };
  },
  created() {
    var vm = this;

    //prepare variable data
    vm.taskDataInQuickPhoto = Object.assign({}, vm.taskDataInDialog);
  },
  computed: {
    currPhotoUrl: function () {
      return this.taskDataInQuickPhoto.photoUrl;
    },
  },
  methods: {
    resetImageUploader() {
      this.$refs.imageUploader.value = "";
    },
    closeMyTaskQuickPhoto() {
      var vm = this;
      //close dialog
      vm.photoDialog = false;
    },
    async addPhoto() {
      if (!navigator.onLine) {
        alert(this.$t("global.internet-connection-error"));
        return;
      }
      try {
        //get references
        var vm = this;

        //show progress
        vm.fileUploading = true;

        //add listener to update with real URL
        vm.addListenerAndUpdate();
        //get file
        var fileInput = document.getElementById("photoFile");
        var file = fileInput.files[0];
        var filePath = "/taskArchive/" + vm.taskId + "/photoUrl/" + file.name;

        var storageRef = storage.ref(filePath);
        var uploadTask = storageRef.put(file);

        //functions.js will add filre url

        uploadTask.on(
          "state_changed",

          function progress(snapshot) {
            //show progress
            var progr = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            vm.progressValue = parseInt(progr);
          },

          function error(err) {
            console.log(err);
          },

          function complete(snapshot) {
            //close dialog, save changes locally
            vm.closeMyTaskQuickPhoto();
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    addListenerAndUpdate() {
      let vm = this;
      //ref
      let listen = db
        .collection("taskArchive")
        .doc(this.taskId)
        .onSnapshot(function (doc) {
          const d = doc.data();

          if (d.photoUrl.length > 1) {
            //update with new photo
            vm.taskDataInQuickPhoto.photoUrl = d.photoUrl;
            vm.$emit("updatePhotoUrl", d.photoUrl);
            vm.fileUploading = false;
            //unsubscribe from listener
            listen();
          }
        });
    },
    async deletePhoto() {
      var vm = this;
      //check if user ment to delete
      //delete the record with confirmation
      var conf = confirm(this.$t("global.delete-photo-confirmation"));
      if (conf) {
        try {
          //delete locally
          vm.taskDataInQuickPhoto.photoUrl = "";
          vm.$emit("updatePhotoUrl", "");

          //close dialog
          vm.closeMyTaskQuickPhoto();

          //delete photo
          await db.collection("taskArchive").doc(vm.taskId).update({
            photoUrl: "",
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
.upload-wrap {
  position: relative;
  display: inline;
}

.upload-btn {
  position: absolute;
  left: 0;
  opacity: 0;
}
</style>
